.specialContainer {
	margin: 1.25rem 0;
	.special {
		border: 1.5px solid $forest;
		padding: 1.5rem;
		border-radius: 0.5rem;
		p {
			margin: 1rem 0;
		}
		.titleCta {
			display: flex;
			justify-content: space-between;
			.title {
				font-size: 1.25rem;
				font-weight: 900;
				color: $forest;
				width: calc(100% - 310px);
				padding: 0 1rem 0 0;
				@include responsive(medium) {
					width: 100%;
					padding: 0;
				}
				h6 {
					color: $forest;
					margin: 0;
					font-size: 1.25rem;
				}
			}
			.dates {
				color: $forest;
				font-size: 14px;
				font-weight: 800;
				line-height: 20px;
				margin: 0.5rem 0 0 0;
			}
			.cta {
				width: 310px;
				@include responsive(medium) {
					display: none;
				}
				button {
					width: 100%;
					a {
						padding: 1rem;
					}
				}
			}
		}
		.mobileCta {
			display: none;
			margin: 1rem 0;
			padding: 0 0 1rem 0;
			border-bottom: 1px solid $forest;
			@include responsive(medium) {
				display: block;
			}
			button {
				width: 100%;
			}
		}
		.description {
			border-bottom: 1px solid $forest;
			margin: 1rem 0;
			@include responsive(medium) {
				border-bottom: 0;
			}
		}
		.disclaimerCopy {
			color: $dark-2;
			font-size: 14px;
			margin: 0.5rem 0 0 0;
			span {
				font-size: inherit;
			}
		}
		.participatingLocations {
			margin: 0.5rem 0 0 0;
			p {
				color: $dark-2;
				font-size: 14px;
				margin: 0;
				span {
					font-size: inherit;
				}
			}
		}
	}
}
