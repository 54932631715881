.error {
	width: 100%;
	text-align: center;
	color: $red;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(96, 96, 96, 0.6);
	z-index: 9999999;

	form {
		padding: 0 !important;
		width: 100% !important;
	}

	hr {
		margin: 1.5rem 0px;
		width: 100%;
		border: 0;
		border-bottom: 1px solid $violet;
	}

	.mdc-form-field {
		width: 80% !important;
		text-align: left !important;
		margin: 0px 0px 20px 0px !important;

		label {
			font-weight: 600;
			font-size: 14px;
		}

		@include responsive(medium) {
			width: 100% !important;
			margin: 0px !important;
		}
	}

	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 640px;
		background: $white;
		border-radius: 4px;
		padding: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		box-sizing: border-box;

		@include responsive(medium) {
			width: 90vw;
			padding: 30px 15px 15px;
		}

		h2 {
			line-height: 24px;
			padding: 0;
			margin: 0 auto;
			font-size: 20px;
			font-weight: 800;
			color: $oxford;

			a {
				color: $violet;
				font-weight: bold;
			}
		}

		p {
			margin: 15px auto 0;
			font-size: 16px;
			color: $dark-2;
		}

		.form-actions {
			margin: 25px auto 0;
			padding: 0;
			box-shadow: none !important;
		}

		button {
			font-size: 16px;
			font-weight: bold;

			@include responsive(medium) {
				margin: 5px auto !important;
				width: 100% !important;
			}
		}

		.material-icons.close {
			position: absolute;
			top: 10px;
			right: 10px;
			font-size: 20px;
			cursor: pointer;
			color: $oxford;
			outline: none;
		}
	}

	.form-actions {
		margin-top: 30px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		font-weight: 400;
		align-items: stretch;

		@include responsive(small) {
			flex-direction: column;
		}

		&.continue {
			@include responsive(medium) {
				margin-top: 45px;
			}
		}

		.action-button {
			width: 277px;
			border-radius: 4px;
			min-height: 56px;
			font-size: 20px;
			cursor: pointer;
			margin: 0 auto;
			padding: 10px;

			&.insurance-redirect,
			&.continue {
				width: 80% !important;
			}

			&.light {
				background-color: $white;
				color: $violet;

				&:hover {
					background: $hover-pink;
					border-color: $magenta;
				}
			}

			&.dark {
				background: $violet;
				color: $white;

				&:hover {
					background: $magenta;
					border-color: $magenta;
				}
			}

			&.green {
				background: $green;
				border: 2px solid $green;
			}
		}
	}

	&.missing-insurance {
		.content {
			#missingInsuranceContinue {
				white-space: nowrap;
			}
			.mdc-form-field {
				align-items: flex-start;
			}
		}
		label {
			padding: 0 0 0 1rem;
			color: $dark-2;
			font-weight: 800;
			font-size: 1rem;
		}
	}

	&.choose-patient-prompt {
		.form-actions .action-button {
			margin: 5px auto;
			width: 100%;
		}

		.or-divider {
			margin-top: 25px;
		}
	}

	&.email-success-modal {
		.check_circle {
			color: $magenta !important;
			font-size: 32px !important;
		}

		h2 {
			color: $violet;
			font-weight: bold;
		}

		a {
			width: 100%;

			button {
				width: 100%;
			}
		}
	}

	&.cancel-appointment-success-modal,
	&.warning-modal {
		.form-actions {
			a {
				width: 100%;

				button {
					width: 100%;
				}
			}
		}
	}

	&.new-patient-prompt {
		p:before {
			content: "*";
		}
	}
	&.last-menstrual-cycle-prompt {
		.form-fields {
			margin-top: 1rem;
		}
		.inline-text-field-container {
			margin: 0 0 1rem 0;
		}
		.future-date-error {
			align-items: center;
			margin: 0 auto;
			p {
				margin: 0;
			}
		}
		.form-actions {
			margin: 1rem auto 0 auto;
		}
	}
}
