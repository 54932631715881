.services-list-page {
	width: 100%;
	.services-search-container {
		min-height: 136px;
		background: $white;
		width: 100%;
		display: flex;

		.search-content {
			max-width: 1250px;
			width: 95%;
			margin: 0 auto;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include responsive(x-large) {
				max-width: 95%;
			}

			@include responsive(bs-large) {
				padding: 5px 10px;
				display: block;
			}

			form {
				width: 80%;

				@include responsive(bs-large) {
					width: calc(100% - 5px);
				}
			}

			.services-search-btn,
			.filter-btn-mobile {
				padding: 15px;
				font-size: 22px;
				font-weight: 600;
				width: 200px;
				border-radius: 4px;
				color: $white;
				border-color: transparent;
				margin-left: 10px;

				@include responsive(bs-large) {
					width: calc(100% - 5px);
					margin: 10px 0px;
				}
			}

			.filter-btn-mobile {
				border-color: $light-green;
				margin: 0px;
			}

			.filter-count-mobile {
				color: $light-green;
				font-size: 20px;
				font-weight: 600;

				@include responsive(medium) {
					font-size: 14px;
				}
			}

			.mobile {
				display: none;

				@include responsive(bs-large) {
					display: block;
				}
			}
		}
	}

	.services-list-container {
		flex-basis: 85%;
		max-width: 1440px;
		background: none;

		@include responsive(medium) {
			flex-basis: 95%;
			margin: 0px auto;
		}

		h1 {
			font-size: 28px;
			font-weight: bold;
			color: $dark-blue-text;
			margin: 0px 0px 14px 10px;

			@include responsive(medium) {
				font-size: 22px;
			}
		}
	}

	.list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.service-group {
			flex: 0 0 calc(100% - 20px);
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			padding: 20px;
			margin: 10px;
			background: $white;
			border-radius: 4px;

			@include responsive(medium) {
				padding: 10px;
				margin: 10px auto;
				flex: 0 0 calc(100%);
			}

			picture {
				height: 274px;
				position: relative;
				overflow: hidden;
				border-radius: 32px 0px;

				@include responsive(small) {
					height: 180px;
				}
			}

			img {
				width: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 32px 0px;

				@include responsive(medium) {
					height: 100%;
				}

				@include responsive(small) {
					max-height: 180px;
				}
			}

			.title {
				font-size: 28px;
				font-weight: 900;
				color: $forest;
				margin-top: 20px;

				@include responsive(medium) {
					font-size: 20px;
				}
			}

			hr {
				width: 100%;
				border: none;
				border-bottom: 1px solid $lime;
				margin: 15px 0;
			}

			.individual-services-list {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				a.service {
					font-size: 20px;
					font-weight: normal;
					color: $dark-2 !important;
					text-decoration: none;
					padding: 10px 30px 10px 0;
					flex: 0 0 33%;
					box-sizing: border-box;

					@include responsive(medium) {
						flex: 0 0 100%;
						font-size: 18px;
					}

					&:hover {
						color: $lime !important;
					}
				}
			}

			&:nth-child(1),
			&:nth-child(2) {
				flex: 0 0 calc(50% - 20px);

				.individual-services-list {
					.service {
						flex: 0 0 100%;
					}
				}

				@include responsive(medium) {
					flex: 0 0 calc(100%);
				}
			}
		}
	}
}

section {
	&.services-list-container {
		display: inline-flex;
		flex-wrap: wrap;
		flex-direction: row;
		background-color: $white;
		border-radius: 6px;
		flex-basis: 70%;
		min-height: 50px;
		margin: 25px auto 40px auto;

		a {
			text-decoration: none;
			color: $violet;

			&:hover {
				text-decoration: underline;
			}
		}

		&.railed {
			margin: 25px auto;

			@include responsive(small) {
				margin: 10px auto;
			}
		}

		&.no-rail {
			flex-basis: 100%;
			max-width: 970px;
		}

		@include responsive(medium) {
			flex-basis: 90%;
			margin: 0px auto;
		}

		@include responsive(small) {
			padding: 8px;
		}

		article {
			margin: 25px;
			width: 100%;
			display: flex;
			flex-basis: 100%;
			flex-direction: column;

			@include responsive(small) {
				margin: 0px;
			}

			figure {
				margin: 0;
				padding: 0;
				margin: 25px 0;

				iframe {
					width: 100% !important;
				}
			}

			.image-container {
				position: relative;
				height: 400px;
				display: flex;
				overflow: hidden;

				@include responsive(medium) {
					height: 51vw;
					width: initial;
				}

				picture {
					width: 100%;

					img {
						width: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);

						@include responsive(medium) {
							height: 51vw;
							width: initial;
						}
					}
				}

				margin-bottom: 25px;

				@include responsive(medium) {
					margin-bottom: 8px;
				}
			}

			.headline {
				background: #2e4873;
				border-radius: 4px;
				word-wrap: break-word;
				overflow: hidden;
				padding: 15px 25px;

				@include responsive(medium) {
					padding: 16px;
				}

				h1 {
					font-size: 28px;
					font-weight: bold;
					color: $white;
					line-height: 30px;
					margin: 0px;
				}

				h2 {
					margin: 5px 0px 0px 0px;
					font-size: 22px;
					font-weight: 600;
					color: $white;
					line-height: 30px;

					@include responsive(medium) {
						font-size: 18px;
					}
				}
			}

			.article-contents {
				margin: 15px 25px 0px 25px;

				@include responsive(small) {
					margin: 0px;
					padding: 0px 15px;
				}

				.sub-headline {
					display: inline-flex;
					flex-direction: row;
					justify-content: space-between;
					width: 100%;

					@include responsive(bs-large) {
						display: block;
					}

					.author-and-date {
						width: 85%;

						@include responsive(bs-large) {
							width: 100%;
						}

						.published-date {
							margin: 5px 0 0 5px;
							font-weight: 600;

							@include responsive(bs-large) {
								margin: -10px 0 10px;
								font-weight: 600;
							}
						}
					}

					.author {
						font-size: 16px;
						font-weight: 600;

						.author-tag {
							margin: 5px 0px 0px 5px;
							display: inline-block;
							border-right: 1px solid $green;

							@include responsive(bs-large) {
								border-right: none;
								margin: 5px 0px 0px 0px;

								&:after {
									content: ", ";
								}
							}

							&:first-child {
								margin: 0px 0px 0px 5px;
								padding-right: 10px;

								@include responsive(bs-large) {
									margin: 0px;
									padding-right: 0px;
								}
							}

							&:not(:first-child) {
								padding: 0px 10px;

								@include responsive(bs-large) {
									padding: 0px 3px;
								}
							}

							&:last-child {
								border-right: none;
								padding-right: 0px;

								&:after {
									content: "";
								}
							}
						}

						@include responsive(bs-large) {
							margin: 16px 0px;
						}
					}

					.share-sheet {
						width: 150px;
						text-align: right;

						@include responsive(bs-large) {
							text-align: left;
						}

						a {
							color: #477c98;
							font-size: 32px;
							margin: 0 5px;

							min-height: 32px;
							min-width: 32px;
							display: inline-block;

							&.non-material-icon {
								filter: invert(42%) sepia(55%) saturate(357%)
									hue-rotate(156deg) brightness(96%)
									contrast(93%);

								min-height: 28px;
								min-width: 28px;
								position: relative;
								top: -2px;

								&.twitter {
									background: url(../../../../img/icons/social/twitter@2x.png) no-repeat;
									background-size: 100% auto;
								}

								&.facebook {
									background: url(../../../../img/icons/social/facebook@2x.png) no-repeat;
									background-size: 100% auto;
								}

								&.mail {
									background: url(../../../../img/icons/social/email@2x.png) no-repeat;
									background-size: 100% auto;
									min-height: 32px;
									min-width: 32px;
									top: 0;
								}
							}
						}

						a:first-child {
							@include responsive(bs-large) {
								margin-left: 0px;
							}
						}
					}
				}

				.content-body {
					img {
						border-radius: 4px;
						width: 100%;
						margin: 0 auto;
						display: block;
						max-width: 100%;
					}

					.health-topics {
						margin-bottom: 25px;

						p {
							margin: 0px;
							font-weight: 700;
							font-size: 17px;
						}

						ul {
							display: inline-flex;
							list-style: none;
							margin: 0px;
							flex-direction: row;
							flex-wrap: wrap;
							padding: 0;

							li {
								font-size: 17px;
								list-style: none;
								font-weight: 600;

								&:after {
									content: ", ";
									margin-right: 5px;
								}

								&:last-child {
									&:after {
										content: "";
										margin-right: 5px;
									}
								}
							}
						}
					}

					.authors {
						border-top: #f2f2f2 2px solid;
						padding-top: 25px;

						ul {
							margin: 15px 25px;
							margin-top: 0;
							display: inline-flex;
							list-style: none;
							flex-direction: column;
							padding: 0;

							li {
								margin-top: 15px;
								display: inline-flex;
								flex-direction: row;
								flex-wrap: nowrap;
								font-size: 17px;
								list-style: none;

								.headshot {
									width: 64px;
									height: 64px;
									max-width: 64px;
									max-height: 64px;
									margin-right: 15px;
									flex-basis: 64px;
								}

								.bio {
									flex-basis: 100%;

									p {
										margin: 0px 0px 0px 10px;
										padding: 0;
									}
								}
							}
						}
					}
				}
			}

		}
	}

	&.related-articles {
		display: inline-flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		flex-basis: 70%;
		min-height: 50px;
		margin: 25px auto 0 auto;
		padding: 0px 50px 40px 60px;
		width: 100%;

		@include responsive(small) {
			flex-basis: 90%;
			margin: 0px auto;
			padding: 15px;
		}

		h3 {
			font-weight: bold;
			width: 100%;

			@include responsive(small) {
				padding: 0px 15px;
			}
		}

		section {
			&.related-article {
				flex-basis: 28%;
				width: 28%;

				@include responsive(small) {
					flex-basis: 100%;
					width: 100%;
					padding: 15px;
				}

				img {
					border-radius: 4px;
					width: 100%;
				}

				h4 {
					margin-top: 5px;
					margin-bottom: 0;
					font-size: 18px;
					font-weight: bold;

					a {
						text-decoration: none;
					}
				}

				h5 {
					margin-top: 5px;
					font-weight: 600;
					line-height: 23px;

					a {
						text-decoration: none;
					}
				}
			}
		}
	}
}
