.physicians-list-grid {
	h4 {
		font-size: 18px;
		font-weight: 900;
		line-height: 20px;
		margin: 8px 0px;
		padding: 0px 24px;
		color: $forest;
	}

	.physicians-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		box-sizing: border-box;
		padding: 1rem 0;
		.providerCardContainer {
			width: calc(100% / 3);
			@include responsive(large) {
				width: 50%;
			}
			@include responsive(bs-large) {
				width: calc(100% / 3);
			}
			@include responsive(medium) {
				width: 50%;
			}
			@include responsive(x-small) {
				width: 100%;
			}
		}
	}
}
