.locations-container {
	.locationsList {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		padding: 1rem 0;
		box-sizing: border-box;
		.locationCardContainer {
			width: 50%;
			@include responsive(medium) {
				width: 100%;
				justify-content: flex-start;
			}
		}
	}
}
